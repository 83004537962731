<template>
  <div class="main-container">
    <div class="text-darkblue text-left">
      <h1>{{ $t('home.directory.title') }}</h1>
    </div>
    <div class="flex justify-space">
      <div class="flex">
        <v-search style="width: 100%" @input="setSearch" />
      </div>

      <div class="export">
        <div class="bloc-file" v-if="file && !isAdmin">
          <span>{{ file.filename }}</span>
          <v-svg
            @click="file.status === 'DONE' ? exportYearByMatricule() : ''"
            :class="[file.status === 'DONE' ? 'pointer' : '', 'icon']"
            :name="iconName(file)"
            h="1.5rem"
          />
        </div>
        <v-button
          :disabled="isExport"
          :label="$t('button.export')"
          :icon="'export'"
          color="pink"
          @action="isAdmin ? exportYear() : generateExportYearByMatricule()"
        />
      </div>
    </div>
    <div>
      <data-grid :columns="columns" :data-tab="suppliers" :is-clickable="true" @action-row="redirectUri($event)" />
    </div>
    <div v-if="suppliers.length">
      <v-pagination
        :total-pages="totalPages"
        :total="totalSuppliers"
        :per-page="rowCount"
        :current-page="currentPage"
        @update-per-page="updateRowCount"
        @pagechanged="onPageChange"
      />
    </div>
  </div>
</template>

<script>
  import VButton from '@/components/Button.vue'
  import VSearch from '@/components/Search.vue'
  import DataGrid from '@/components/DataGrid.vue'
  import VPagination from '@/components/Pagination.vue'
  import VSvg from '@/components/base/Svg'
  import { mapState, mapActions, mapGetters } from 'vuex'
  import { saveAs } from 'file-saver'
  import { convertDateToDDMMYYYY } from '@/utils/date'
  import { sendErrorToast } from '@/utils/utils'
  import { DONE, ERROR, PENDING } from '@/utils/CONF'

  import { extendWithAuthorization, http } from '@/http'

  import { firebaseApp } from '@/main.js'
  import { getFirestore, doc, getDoc } from 'firebase/firestore'

  export default {
    name: 'Home',
    components: {
      VButton,
      VSearch,
      DataGrid,
      VPagination,
      VSvg
    },
    data() {
      return {
        search: [],
        isActivated: false,
        displayed: 10, // Number of cards to displayed in oneShot

        currentPage: 1,
        rowCount: 10,
        suppliers: [],
        totalSuppliers: 0,
        file: null,
        isExport: false
      }
    },
    computed: {
      ...mapState('user', ['campaignYear', 'userMatriculeConnexion']),
      ...mapGetters('user', ['isAdmin']),
      columns() {
        return this.suppliers.length > 0
          ? Object.keys(this.suppliers[0]).map(e => {
              return this.$t(`home.directory.${e}`)
            })
          : []
      },
      totalPages() {
        return Math.ceil(this.totalSuppliers / this.rowCount)
      },
      offset() {
        return this.rowCount * this.currentPage
      }
    },
    methods: {
      ...mapActions('supplierPurchase', ['fetchSupplierPurchaseExportYear']),
      iconName(file) {
        switch (file.status) {
          case DONE:
            return 'download'
          case PENDING:
            return 'spinner-blue'
          case ERROR:
            return 'error'
        }
      },
      async generateExportYearByMatricule() {
        try {
          this.isExport = true
          const extendedHttp = await extendWithAuthorization(http)
          const searchParams = {
            matricule: this.userMatriculeConnexion
          }
          await extendedHttp.get(`directory/generate-export/${this.campaignYear}`, { searchParams })
        } catch (error) {
          sendErrorToast('Erreur lors de la génération du fichier')
        }
      },
      async exportYearByMatricule() {
        let nameFile = `TH_${this.campaignYear}_${this.userMatriculeConnexion}_${convertDateToDDMMYYYY(new Date(), '_')}.xlsx`
        let nameBucket = `${process.env.VUE_APP_FIREBASE_URL_BUCKET}/TH_Matricule`
        let csvdata = await this.fetchSupplierPurchaseExportYear({ nameFile: nameFile, nameBucket: nameBucket })
        saveAs(csvdata.file[0], nameFile)
      },
      async exportYear() {
        this.isExport = true
        let nameFile = 'TH-' + this.campaignYear + '.xlsx'
        let nameBucket = `${process.env.VUE_APP_FIREBASE_URL_BUCKET}/SIGNATURE/TH_All`
        let csvdata = await this.fetchSupplierPurchaseExportYear({ nameFile: nameFile, nameBucket: nameBucket })
        saveAs(csvdata.file[0], nameFile)
        this.isExport = false
      },
      onPageChange(currentPage) {
        this.currentPage = currentPage
        this.fetchDatas()
      },
      updateRowCount(value) {
        this.rowCount = value
        this.currentPage = 1
        this.fetchDatas()
      },
      setSearch(val) {
        this.search = val
        this.currentPage = 1
        this.fetchDatas()
      },
      removeSearch(index) {
        this.search.splice(index, 1)
        this.fetchDatas()
      },
      async fetchDatas() {
        this.$store.commit('common/SET_LOADING', true)
        try {
          const extendedHttp = await extendWithAuthorization(http)
          const dataSuppliers = await extendedHttp
            .get(`api/suppliers`, {
              searchParams: {
                search: this.search,
                campaignYear: this.campaignYear,
                userMatriculeConnexion: this.isAdmin ? '' : this.userMatriculeConnexion,
                row_count: this.rowCount,
                offset: this.offset - this.rowCount
              }
            })
            .json()
          this.suppliers = dataSuppliers.result ?? []
          this.totalSuppliers = dataSuppliers.total ?? 0
        } catch (err) {
          this.suppliers = []
        }
        this.$store.commit('common/SET_LOADING', false)
      },
      redirectUri(index) {
        const datas = this.suppliers[index]
        this.$router.push({
          name: 'supplier',
          query: {
            paymentSupplierVATNumber: datas.paymentSupplierVATNumber
          }
        })
      }
    },
    watch: {
      campaignYear() {
        this.fetchDatas()
      }
    },
    async created() {
      this.fetchDatas()

      let docName = `TH_${this.campaignYear}_${this.userMatriculeConnexion}_${convertDateToDDMMYYYY(new Date(), '_')}.xlsx`
      console.log('firebaseApp', firebaseApp)

      const db = getFirestore(firebaseApp)

      const documentRef = doc(db, 'TH_FILES', docName)
      const documentSnapshot = await getDoc(documentRef)

      this.file = documentSnapshot.exists() ? documentSnapshot.data() : null
      if (this.file?.status !== PENDING) this.isExport = false
    }
  }
</script>

<style scoped lang="scss">
  .main-container {
    margin: 0 2rem;
    padding-bottom: 20rem;
  }
  .export {
    display: flex;
    align-items: center;
    .bloc-file {
      padding: 0px 8px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      margin-right: 10px;
      span {
        font-weight: bold;
      }
      .icon {
        margin-left: 5px;
      }
    }
  }
</style>
